import supabase from "../heydaySbClient";
import { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function Contacts() {
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [content, setContent] = useState([]);
  const [contentDescription, setContentDescription] = useState(null);
  const [contentDescriptionId, setContentDescriptionId] = useState(null);
  const [contentTitle, setContentTitle] = useState("");
  const [contentPhone, setContentPhone] = useState("");
  const [contentEmail, setContentEmail] = useState("");
  const [contentWebsite, setContentWebsite] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [saveMessage, setSaveMessage] = useState("");

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    async function getContent() {
      const { data, error } = await supabase
        .from("contacts")
        .select("*")
        .eq("contact_category_id", selectedCategoryId);
      if (error) console.log("error", error);
      else setContent(data);
    }

    if (selectedCategoryId) getContent();
  }, [selectedCategoryId, saveMessage]);

  

  async function getCategories() {
    const { data, error } = await supabase
      .from("contact_categories")
      .select("*");
    if (error) console.log("error", error);
    else setCategories(data);
  }

  function contentDescriptionHandle(e) {
    if (e.content === null) {
      e.content = "";
    }
    if (e.name === null) {
      e.name = "";
    }
    if (e.phone_number === null) {
      e.phone_number = "";
    }
    if (e.email === null) { 
      e.email = "";
    }
    if (e.website === null) {
      e.website = "";
    }
    setContentDescription(e.content);
    setContentTitle(e.name);
    setContentDescriptionId(e.id);
    setContentPhone(e.phone_number);
    setContentEmail(e.email);
    setContentWebsite(e.website);
  }

  function handleCategoryChange(categoryId) {
    setSelectedCategoryId(categoryId);
  }

  const editorRef = useRef(null);

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const saveContent = async () => {
    if (editorRef.current && contentDescriptionId) {
      setIsSaving(true);
      setSaveMessage("Saving...");
      
      const editedContent = editorRef.current.getContent() || null;
      const contentTitleValue = contentTitle || null;
      const contentPhoneValue = contentPhone || null;
      const contentEmailValue = contentEmail || null;
      const contentWebsiteValue = contentWebsite || null;
      const contentId = contentDescriptionId;
  
      try {
        const { data, error } = await supabase
          .from("contacts")
          .update({ 
            name: contentTitleValue, 
            content: editedContent, 
            phone_number: contentPhoneValue, 
            email: contentEmailValue, 
            website: contentWebsiteValue 
          })
          .eq("id", contentId);
  
        if (error) {
          setSaveMessage("Er ging iets mis. Probeer opnieuw.");
          throw error;
        }
  
        setSaveMessage("Aanpassingen succesvol opgeslagen.");
        
      } catch (error) {
        console.error("Error bij opslaan:", error);
        setSaveMessage("Er ging iets mis. Probeer opnieuw.");
      } finally {
        setTimeout(() => {
          setIsSaving(false);
          setSaveMessage("");
        }, 2000); // Resets the saving state and message after 2 seconds
      }
    }
  };
  

  return (
    <div className="w-full h-full">
      <div className="flex justify-between items-end h-20 mb-2">
        <h1 className="text-4xl font-thin mt-5 flex items-center uppercase text-gray-400">
          Contacten
        </h1>
        {contentDescriptionId && !isSaving && (
          <button
            onClick={saveContent}
            className="px-3 py-2 bg-orange-300 hover:bg-orange-400 rounded-lg text-white font-bold"
          >
            Opslaan
          </button>
        )}
        {isSaving && <p className="text-orange-500">{saveMessage}</p>}
      </div>
      <div className="flex">
        <div className="flex-1 space-y-4">
          <div className="mb-12">
            <h2 className="font-medium text-orange-400 mb-2">
              Selecteer een Categorie:
            </h2>
            <select
              className="border border-gray-300 rounded-md p-2"
              onChange={(e) => handleCategoryChange(e.target.value)}
              value={selectedCategoryId || ""}
            >
              <option value="">...</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          {selectedCategoryId && <h2 className="font-medium text-orange-400">
            Klik op een Contact Item:
          </h2>}
          <div className="flex flex-col mr-16 space-y-2">
            {content.map((item) => (
              <button 
              className="text-left text-gray-800 hover:text-orange-400 bg-gray-100 hover:bg-gray-200 rounded-md p-2"

              key={item.id} onClick={() => contentDescriptionHandle(item)}>
                {item.name}
              </button>
            ))}
          </div>
        </div>
        <div className="flex-1">
          <input
            className="w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 mb-1 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
            placeholder="Titel..."
            type="text"
            value={contentTitle}
            onChange={(e) => setContentTitle(e.target.value)}
          />
          <input
            className="w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 mb-1 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
            placeholder="Telefoonnummer..."
            type="text"
            value={contentPhone}
            onChange={(e) => setContentPhone(e.target.value)}
          />
          <input
            className="w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 mb-1 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
            placeholder="E-mail..."
            type="text"
            value={contentEmail}
            onChange={(e) => setContentEmail(e.target.value)}
          />
          <input
            className="w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 mb-4 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
            placeholder="Website..."
            type="text"
            value={contentWebsite}
            onChange={(e) => setContentWebsite(e.target.value)}
          />
          <div className="mb-24">
            <Editor
              apiKey="uj4j918ze1mhxmx2s0lydgtgm56cd69bpbn5hl8253b45d9y"
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={contentDescription}
              init={{
                height: 300,
                menubar: false,
                plugins: ['link', 'paste', 'image'],
                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image link',

                // ... other editor options
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
