import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TopMenu from "../components/TopMenu";
import Overview from "./Overview";
import Footer from "../components/Footer";
import Content from "./Content";
import Contacts from "./Contacts";
import ProfileSettings from "./ProfileSettings";

export default function IndexPage() {
  return (
    <Router>
      <div className="flex flex-col h-screen">
        <div className="flex-none h-16">
          <TopMenu />
        </div>
        <div className="grow flex justify-center items-center container max-w-5xl mx-auto">
          <Routes>
            <Route path="/" element={<Overview />} />
            <Route path="/content" element={<Content />} />
            <Route path="/contacts" element={<Contacts />} />
            {/* <Route path="/tips" element={<h1>tips</h1>} /> */}
            <Route path="/settings" element={<ProfileSettings /> } />
          </Routes>
        </div>
        <div className="flex-none h-12">
          <Footer />
        </div>
      </div>
    </Router>
  );
}
