import { useState, useEffect } from "react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import supabase from "./heydaySbClient";
import IndexPage from "./pages/Index";
import icon from "./icon.png";

export default function App() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (!session) {
    return (
      <div className="mx-auto h-screen flex flex-col justify-center items-center">
        <div className="flex justify-start items-center mb-6 px-4 rounded-md" style={{backgroundColor: "#FAF3EE"}}>
          <img src={icon} alt="icon" className="w-12 h-12" />
          <div>
            Heyday <strong>Admin</strong>
          </div>
        </div>

        <Auth
          className="auth-container"
          supabaseClient={supabase}
          appearance={{
            theme: ThemeSupa,
            variables: {
              default: {
                colors: { brand: "orange", brandAccent: "darkorange" },
              },
            },
          }}
          providers={[]}
          showLinks={false}
        />
      </div>
    );
  } else {
    return <IndexPage />;
  }
}
