import LogoutButton from "./LogoutButton";
import { Link, useLocation } from "react-router-dom";
import icon from "../icon.png";

export default function TopMenu() {
  const location = useLocation();

  const linkStyle = (path) => {
    return location.pathname === path
      ? "text-orange-400 underline font-bold"
      : "text-gray-800 ";
  }

  return (
    <div className="flex w-full h-16 pl-6 pr-6 bg-gray-100 justify-between items-center">
      <div className="flex justify-start items-center">
      <img src={icon} alt="icon" className="w-12 h-12" />
      <Link to="/">Heyday <strong>Admin</strong></Link>
      </div>
      <div className="flex space-x-4">
        {/* <Link to="/" className={ linkStyle('/') }>Overview</Link> */}
        <Link to="/content" className={ linkStyle('/content') }>Bibliotheek</Link>
        <Link to="/contacts" className={ linkStyle('/contacts') }>Contacten</Link>
        {/* <Link to="/tips" className={ linkStyle('/tips') }>Tips</Link> */}
        <Link to="/settings" className={ linkStyle('/settings') }>Instellingen</Link>
        <div className="">|</div>
        
        <LogoutButton />
      </div>
    </div>
  );
}
