import welcomeImage from "../welcome.svg";
import { Link } from "react-router-dom";

export default function OverviewPage() {
  return (
    <div className="flex flex-col mt-24">
      <div className="flex h-96">
        <div className="flex-1"></div>
        <img src={welcomeImage} alt="welcome" />
        <div className="flex-1"></div>
      </div>
      {/* <h1 className="flex text-2xl font-bold h-24 items-center">Overview page</h1> */}
      <div className="flex flex-col justify-center items-center mt-12">
        <p className="text-xl">Welkom op het Heyday admin paneel.</p>
        <p>Hier kan je de content van de Heyday app beheren.</p>
        <Link to="/content" className="w-32 bg-orange-300 hover:bg-orange-400 text-white flex items-center justify-center my-1 py-2 mt-6 rounded-lg">
          Bibliotheek
        </Link>
        <Link to="/contacts" className="w-32 bg-orange-300 hover:bg-orange-400 text-white flex items-center justify-center my-1 py-2 rounded-lg">
          Contacten
        </Link>
      </div>
    </div>
  );
}
