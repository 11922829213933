import supabase from "../heydaySbClient";

function LogoutButton() {
  const logoutHandler = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.log("Error logging out:", error.message);
    }
  };

  return <button onClick={logoutHandler}>uitloggen</button>;
}

export default LogoutButton;
