import supabase from "../heydaySbClient";
import { useState, useEffect } from "react";

export default function ProfileSettings() {
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const getUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user) {
        setUserEmail(user.email);
      }
    };

    getUser();
  }, []);

  const isValidPassword = (password) => {
    if (!password) {
      return "Wachtwoord kan niet leeg zijn";
    }
    if (password.length < 8) {
      return "Wachtwoord moet ten miste 8 karakters lang zijn en ten minste 1 letter en 1 nummer bevatten.";
    }
    if (!/\d/.test(password) || !/[a-zA-Z]/.test(password)) {
      return "Wachtwoord moet ten minste 1 letter en 1 nummer bevatten.";
    }
    return "";
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setPasswordError("");

    // Validate Password
    const validationError = isValidPassword(password);
    if (validationError) {
      setPasswordError(validationError);
      return;
    }

    // Check if passwords match
    if (password !== repeatPassword) {
      setMessage("Wachtwoorden komen niet overeen");
      return;
    }

    const { error } = await supabase.auth.updateUser({
      password,
    });

    if (error) {
      setMessage(error.message);
    } else {
      setMessage("Wachtwoord succesvol aangepast");
      setPassword("");
      setRepeatPassword("");
    }
  };

  return (
    <div className="flex flex-col w-72">
      <h1>Wachtwoord aanpassen:</h1>
      <form onSubmit={handleUpdate}>
        <div className="py-2">
          <label>Email: <strong>{userEmail}</strong></label>
        </div>
        <div>
          <label>Nieuw wachtwoord:</label>
          <input
            className="w-full border border-slate-300 rounded-md py-2 pl-2 pr-3 mb-1 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
        </div>
        <div>
          <label>Herhaal nieuw wachtwoord:</label>
          <input
            className="w-full border border-slate-300 rounded-md py-2 pl-2 pr-3 mb-1 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
            type="password"
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
          />
        </div>
        <div className="w-64 bg-orange-300 hover:bg-orange-400 text-white flex items-center justify-center my-1 py-2 rounded-lg">
          <button type="submit">Wachtwoord aanpassen</button>
        </div>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}
